<template>
  <div class="top-box" v-if="versionId">
    <span class="inline"></span>
    <span class="text">版本列表-编辑</span>
  </div>
  <a-form class="myForm box-shadow" :label-col="{ span: 2 }" :wrapper-col="{ span: 21 }" :model="formState" :rules="rules" ref="formRef">
    <a-form-item label="客户端:" name="client">
      <a-select
      :disabled="disabled"
      v-model:value="formState.client_id"
      style="width: 100%"
      placeholder="请选择客户端"
      @change="handleChange">
        <a-select-option :value="item.id" v-for="item in clientObj" :key="item.id">{{item.value}}</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item label="版本号:" name="version">
      <a-input  placeholder="请输入版本号" :maxlength="18" autocomplete="off" v-model:value="formState.version" :disabled="disabled"/>
    </a-form-item>
    <a-form-item label="更新内容:" name="content">
      <a-textarea :maxlength="500" placeholder="请输入更新内容" :rows="4" autocomplete="off" v-model:value="formState.content"/>
    </a-form-item>
    <a-form-item label="更新文件:" v-show="isShow && files.length==0 && !disabled">
      <upload-ab :upObj="upObj1" :list="files" @changeFile="changeFile" ref="upload" id="uploadW" :isAuto="true">上传</upload-ab>
    </a-form-item>
    <a-form-item label="更新文件:" v-show="!isShow && files.length==0 && !disabled">
      <upload-ab :upObj="upObj2" :list="files" @changeFile="changeFile" ref="upload" id="uploadA" :isAuto="true">上传</upload-ab>
    </a-form-item>
    <a-form-item label="更新文件:" v-if="files.length && !disabled">
      <div class="flex flie-box">
        <FileZipOutlined class="icon-file"/>
        <CloseOutlined class="icon-delete" @click="deleteFile"/>
        <div>
          <div>{{files[0].file_name}}</div>
          <div>{{files[0].file_size}}</div>
        </div>
      </div>
    </a-form-item>
    <a-form-item label="资源地址:" v-show="versionId">
      <div>{{formState.download_url}}</div>
      <upload-ab :upObj="upObj1" :list="files" @changeFile="changeFile" ref="upload" id="uploadW" :isAuto="true"  v-if="isShow">重新上传资源</upload-ab>
      <upload-ab :upObj="upObj2" :list="files" @changeFile="changeFile" ref="upload" id="uploadA" :isAuto="true" v-if="!isShow">重新上传资源</upload-ab>
      <!-- <upload-ab :upObj="upObj2" :list="files" @changeFile="changeFile" ref="upload" id="uploadA" :isAuto="true">重新上传资源</upload-ab> -->
    </a-form-item>
    <!-- <a-form-item label="安装路径:" v-show="isShow">
      <div>
        <div class="flex flex-align-c" :class="{'mt-10': index > 0}" v-for="(item, index) in formState.details" :key="index">
          <a-input  placeholder="aaa.zip" autocomplete="off" v-model:value="item.file_name"/>
          <ArrowRightOutlined  class="icon"/>
          <a-input  placeholder="bin\core" autocomplete="off" v-model:value="item.install_path"/>
          <PlusSquareOutlined class="add-icon" @click="addInputList(index)"/>
          <MinusSquareOutlined class="add-icon" @click="deleteInputList(index)" v-if="formState.details.length > 1"/>
        </div>
      </div>
    </a-form-item> -->
    <a-form-item :wrapper-col="{ span: 3, offset: 2 }" v-if="!disabled">
      <a-button
          type="primary"
          htmlType="submit"
          @click.prevent="onSubmit(fetchCreateVersion)">
          提交
        </a-button>
    </a-form-item>
    <a-form-item :wrapper-col="{ span: 3, offset: 2 }" v-else>
      <a-button
          type="primary"
          htmlType="submit"
          @click.prevent="checkEditVersion(fetchEditVersion)"
        >
          保存
        </a-button>
    </a-form-item>
  </a-form>
</template>

<script>
import { computed, defineComponent, reactive, ref, onMounted } from 'vue'
import { CLIENT, FILE_TYPE } from '../hooks/config'
import uploadAb from '@/views/encyclopediasManger/components/upload-ab'
import { ArrowRightOutlined, PlusSquareOutlined, MinusSquareOutlined, FileZipOutlined, CloseOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { isValid, isEmoji } from '@/hooks/validate-hooks'
import { OSSURL } from '@/hooks/ali-oss'
import { createVersion, getVersionDes, editVersion } from '@/api/index'
import { filterKeys } from '@/hooks/common-hooks'
import { useRouter } from 'vue-router'

const validatename = async (rule, value) => {
  const reg = /[^\d.]/g
  if (!isValid(value)) {
    return Promise.reject(new Error('请输入版本号'))
  } else if (!isEmoji(value)) {
    return Promise.reject(new Error('包含非法字符'))
  }
  if (reg.test(value)) {
    return Promise.reject(new Error('包含非法字符'))
  }
  return Promise.resolve()
}

const validatecontent = async (rule, value) => {
  if (!isValid(value)) {
    return Promise.reject(new Error('请输入更新内容'))
  } else if (!isEmoji(value)) {
    return Promise.reject(new Error('包含非法字符'))
  }
  return Promise.resolve()
}

export default defineComponent({
  name: 'createVersion',
  props: ['id'],
  components: {
    uploadAb,
    // ArrowRightOutlined,
    // PlusSquareOutlined,
    // MinusSquareOutlined,
    FileZipOutlined,
    CloseOutlined
  },
  setup (props, ctx) {
    const router = useRouter()
    const versionId = computed(() => props.id)
    const formRef = ref()
    const upload = ref()
    const formState = reactive({
      client_id: 7, // 默认pc
      version: '', // 当前版本
      content: '', // 更新内容
      download_url: '', // 下载地址
      details: [{
        file_name: '',
        install_path: ''
      }] // 安装路劲
    })
    const files = ref([])
    const rules = {
      version: [{ required: true, trigger: 'blur', validator: validatename, whitespace: true }],
      content: [{ required: true, trigger: 'blur', validator: validatecontent, whitespace: true }]
    }
    const isShow = computed(() => {
      return formState.client_id === 7
    })

    const changeFile = (fileList) => {
      files.value = fileList
      formState.download_url = OSSURL + '/' + files.value[0].file_path
    }
    // 验证安装路劲
    const checkDetails = () => {
      return formState.details.every(item => (item.file_name.trim() !== '' && item.install_path.trim() !== ''))
    }
    // 添加路劲
    const addInputList = (index) => {
      if (!checkDetails()) {
        message.error('安装路径不能为空')
        return false
      }
      formState.details.splice(index + 1, 0, {
        file_name: '',
        install_path: ''
      })
    }
    // 删除路劲
    const deleteInputList = (index) => {
      formState.details.splice(index, 1)
    }
    const handleChange = (value) => {
      formState.client_id = value
    }
    // 删除文件
    const deleteFile = () => {
      files.value.pop()
    }
    // 创建
    const onSubmit = (callback) => {
      formRef.value.validate().then(() => {
        if (files.value.length === 0) {
          message.error('请选择更新文件')
          return false
        }
        // if (formState.client_id === 7 && !checkDetails()) {
        //   message.error('安装路径不能为空')
        //   return false
        // }
        callback && callback()
      })
    }
    // 编辑验证
    const checkEditVersion = (callback) => {
      formRef.value.validate().then(() => {
        // if (formState.client_id === 7 && !checkDetails()) {
        //   message.error('安装路径不能为空')
        //   return false
        // }
        callback && callback()
      })
    }
    const resetData = () => {
      formRef.value.resetFields()
      files.value.pop()
      // formState.details = [{
      //   file_name: '',
      //   install_path: ''
      // }]
    }
    // 创建版本
    const fetchCreateVersion = () => {
      // upload.value.fetchSend(() => {
      formState.download_url = OSSURL + '/' + files.value[0].file_path
      createVersion(formState).then(() => {
        message.success('创建版本成功')
        resetData()
        ctx.emit('changeIndex', 0)
      })
      // })
    }
    // 查询版本详情
    const fetchVersionDes = () => {
      getVersionDes({
        id: versionId.value
      }).then(res => {
        filterKeys(formState, res.data)
        formState.details = res.data.version_details
      })
    }
    const disabled = computed(() => {
      if (versionId.value) {
        return true
      } else {
        return false
      }
    })
    // 编辑版本
    const fetchEditVersion = () => {
      editVersion({
        id: versionId.value,
        content: formState.content,
        details: formState.details,
        download_url: formState.download_url
      }).then(() => {
        message.success('修改版本信息成功')
        fetchVersionDes()
        // router.replace({ name: 'editionManger' })
      })
    }
    const resetDownUrl = () => {
      upload.value.click()
    }
    // 如果是编辑页面，则调用查询接口
    onMounted(() => {
      if (versionId.value) {
        fetchVersionDes()
      }
    })
    return {
      formRef,
      upload,
      formState,
      files,
      isShow,
      rules,
      changeFile,
      addInputList,
      deleteInputList,
      handleChange,
      deleteFile,
      onSubmit,
      fetchCreateVersion,
      versionId,
      disabled,
      fetchEditVersion,
      checkEditVersion,
      resetDownUrl
    }
  },
  data () {
    return {
      clientObj: CLIENT,
      upObj1: [FILE_TYPE[7]],
      upObj2: [FILE_TYPE[8]]
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/scss/theme.scss';
@import '@/scss/form.scss';
.icon{
  padding: 0 40px;
}
.add-icon{
  padding: 0 10px;
  font-size: 20px;
  cursor: pointer;
  color: #999;
  &:hover{
    color: $primary-color;
  }
  &:last-child{
    padding-right: 0;
  }
}
.flie-box{
  position: relative;
  align-items: center;
  background-color: #eee;
  .icon-file{
    font-size: 40px;
    color: #999;
    padding: 10px;
  }
  div{
    line-height: 20px;
  }
  .icon-delete{
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px;
    font-size: 16px;
    color: #999;
    &:hover{
      color: $primary-color;
      cursor: pointer;
    }
  }
}
</style>
