import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_upload_outlined = _resolveComponent("upload-outlined")
  const _component_a_button = _resolveComponent("a-button")
  const _component_ali_oss = _resolveComponent("ali-oss")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_a_button, { onClick: _ctx.handleClick }, {
      default: _withCtx(() => [
        _createVNode(_component_upload_outlined),
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }, 8, ["onClick"]),
    _createVNode(_component_ali_oss, {
      id: _ctx.id,
      point: _ctx.point,
      ref: "updateFile",
      upObj: _ctx.upObj,
      isAuto: _ctx.isAuto,
      oldList: _ctx.list,
      filter: _ctx.filter,
      isMore: _ctx.isMore,
      callBack: _ctx.callBack,
      onAutoCallback: _ctx.autoCallback,
      onChangeFile: _ctx.changeFile
    }, null, 8, ["id", "point", "upObj", "isAuto", "oldList", "filter", "isMore", "callBack", "onAutoCallback", "onChangeFile"])
  ], 64))
}