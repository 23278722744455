export const CLIENT = [{
  id: 7,
  value: 'PC版'
}, {
  id: 8,
  value: '移动端'
}]

export const FILE_TYPE = {
  7: {
    lable: 'windows',
    filter: '.zip',
    bunket: 'version/windows', // 必传
    maxNum: 1,
    type: '',
    isRealName: true // 是否重命名
  },
  8: {
    lable: 'android',
    filter: '.apk',
    bunket: 'version/android', // 必传
    maxNum: 1,
    type: '',
    isRealName: true // 是否重命名
  },
  9: {
    lable: 'ios',
    filter: '.ipa',
    bunket: 'version/ios', // 必传
    maxNum: 1,
    type: '',
    isRealName: true // 是否重命名
  }
}
