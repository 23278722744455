
import { defineComponent, ref, watch } from 'vue'
import aliOss from '@/components/ali-oss.vue'
import { UploadOutlined } from '@ant-design/icons-vue'
export default defineComponent({
  props: {
    id: {
      type: String,
      default: 'file'
    },
    list: {
      type: Array,
      required: true
    },
    upObj: {
      type: Array,
      required: true
    },
    isAuto: {
      type: Boolean,
      default: false
    }, // 是否自动上传，默认不自动上传
    filter: {},
    isMore: {},
    callBack: {},
    point: {
      type: Boolean,
      default: false
    }
  },
  components: {
    UploadOutlined,
    aliOss
  },
  emits: ['changeFile', 'autoCallback'],
  setup (props, context) {
    const updateFile = ref()
    const handleClick = () => {
      updateFile.value.clearFile()
      const ele = document.getElementById(props.id)
      if (ele) {
        ele.click()
      }
    }

    // 文件选择的回调
    const changeFile = (lists: never[]) => {
      context.emit('changeFile', lists)
    }
    // 上传
    const fetchSend = (callback: () => {}) => {
      return updateFile.value.fetchSend(callback)
    }
    const autoCallback = (list: never[]) => {
      context.emit('autoCallback', list)
    }
    return {
      updateFile,
      handleClick,
      changeFile,
      fetchSend,
      autoCallback
    }
  }
})
